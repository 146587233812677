import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/e-commerce-animation.json";
import Footer from "../../components/Footer";
import "../../css/e-commerce.css";
import angular from "../../assets/img/angular.png";
import bootstrap from "../../assets/img/bootstrap.png";
import react from "../../assets/img/react.png";
import postgresql from "../../assets/img/PostgreSQL-Logo 2.png";
import node from "../../assets/img/node.png";
import reactJS from "../../assets/img/reactJS.png"
import python from "../../assets/img/python.png"
import mongodb from "../../assets/img/mongodb.png"
import Ecommerce_svg from "../../components/svgs/Service_ecommerce"
import Loader_page from "../../components/Loader_page";

const Ecommerce = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Set loading state to true initially
  const [loading, setLoading] = useState(true);
      
  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {

      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
    
  // If page is in loading state, display 
  // loading message. Modify it as per your 
  // requirement.
  if (loading) {
      return <Loader_page />
  }
    
  // If page is not in loading state, display page.
  else {
    return (
      <>
      <Helmet>
        <title>Bintech Services | E-Commerce Solution</title>
        <meta name="title" content="Build an online store in just 15 days" />
        <meta name="description" content="integrated e-commerce solution which aids merchants with facilities such as Multi Warehouse management, Real Time Inventory management, Store and Website Integration, and various other similar features." />
        <meta name="keywords" content="e commerce website solution, complete e commerce solution, e commerce solution india, custom e commerce solution, wholesale e commerce solution, e commerce solution software" />
      </Helmet>
      <div className="service e-commerce">
          {/* section 1 */}
          <div className="section-1">
            <div className="container">
              <div className="row">
                <div className="ps-lg-5 col-12 col-md-6 d-flex flex-column justify-content-center">
                  <div className="ps-lg-5 container">
                    <h2 className="page-title">E-Commerce</h2>
                    <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/e-commerce">E-Commerce</a>
                        </li>
                      </ol>
                    </nav>
                    <div className="img-fluid d-block d-md-none my-4">
                      <Ecommerce_svg />
                    </div>
                    {/* <img src={technology_software} alt="" className="img-fluid d-block d-md-none my-4" /> */}
                    <p className="w-75">
                      Our E-Commerce Solution is a Software as Services (SaaS) which
                      enables customers to build an online store in just 15 days.
                      It’s an integrated platform which aids merchants with
                      facilities such as Multi Warehouse management, Real Time
                      Inventory management, Store and Website Integration, and
                      various other similar features.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center">
                  <Ecommerce_svg />
                </div>
              </div>
            </div>
          </div>
          {/* section 1 */}

          {/* section 2 */}
          <div className="section-2">
            <div className="container">
              <h4 className="mb-md-3 service-section-title text-orange fw-semibold">
                Extinguished Feature of our Software
              </h4>
              <div className="row">
                <div className="col-12 col-lg-12 order-sm-1 order-md-1 order-lg-0">
                  <div className="container">

                    <br />

                    <ul>
                      <li>
                        Build with Modern Technology Stack: Angular, Reactjs,
                        Bootstrap, Nodejs, PostgreSQL.
                      </li>
                      <li>
                        Administrative Panel with capabilities to manage products
                        with Search Engine Optimisation and Social Media Presence
                        from a single screen.
                      </li>
                      <li>
                        A Single micro Enterprise Planning Software (ERP) which can
                        manage live inventory between Warehouses, Retail Stores,
                        Point of Sales(POS) and Website.
                      </li>
                      <li>
                        Software capable of serving all scale of companies ranging
                        from Small, Medium, Large Scale Enterprises.
                      </li>
                      <li>
                        User Friendly Interface which allows non-technical users to
                        operate the software.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-12 col-lg-4 order-sm-0 order-md-0 order-lg-1">
      <div className="d-flex">
        <div className="img-container">
          <img src={angular} alt="Angular" />
        </div>
        <div className="img-container">
          <img src={bootstrap} alt="Bootstrap" />
        </div>
      </div>
      <div className="d-flex">
        <div className="img-container">
          <img src={react} alt="React" />
        </div>
        <div className="img-container">
          <img src={postgresql} alt="PostgreSQL" />
        </div>
      </div>
      <div className="img-container">
        <img src={node} alt="Node" />
      </div>
    </div> */}
              </div>
            </div>
          </div>
          {/* section 2 */}

          {/* section 3 */}

          <div className="section-3">
            <div className="container">
              <div className="sub-section-1">
                <h4 className="service-section-title text-orange fw-semibold">
                  For whom is this software useful?
                </h4>
                <br />
                <div className="row">
                  <div className="col-12 col-md-6 mr-4">
                    <div className="img-container"></div>
                    <p className="fw-400 mt-3">Product Manufacturers</p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="img-container"></div>
                    <p className="fw-400 mt-3">Product Traders</p>
                  </div>
                </div>
              </div>
              <div className="sub-section-2">
                <h4 className="service-section-title text-orange fw-semibold">
                  Modules Available With the software
                </h4>
                <br />

                <div className="row g-4 available-modules">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Product Module
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Inventory and Stock Module
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Sales and Marketing Module
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Invoicing and Order Management
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Accounting Module
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div class="card h-100">
                      <div class="card-body">
                        Reseller Module
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="sub-section-3">
                <h4 className="service-section-title text-orange fw-semibold">
                  Product Technology Stack
                </h4>
                <br />
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={angular} alt="Angular" />
                    </div>
                    <p className="fw-400 mt-3">Angular</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={node} alt="Node" />
                    </div>
                    <p className="fw-400 mt-3">NodeJS</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={postgresql} alt="PostgreSQL" />
                    </div>
                    <p className="fw-400 mt-3">PostgreSQL</p>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={reactJS} alt="Angular" />
                    </div>
                    <p className="fw-400 mt-3">ReactJS</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={python} alt="Node" />
                    </div>
                    <p className="fw-400 mt-3">Python</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="img-container">
                      <img src={mongodb} alt="PostgreSQL" />
                    </div>
                    <p className="fw-400 mt-3">MongoDB</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 3 */}

          <Footer />
        </div></>
    );
  }
};
export default Ecommerce;
