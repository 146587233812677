import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Footer from '../../components/Footer_services';
import astrik from "../../assets/svg/astrik.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import "../../css/home.css"
import "../../css/services.css"
import "../../css/blog_single.css"
import Loader_page from "../../components/Loader_page";
import blog_single_hero from "../../assets/svg/blog-single-hero.svg"
import blog_single_bob from "../../assets/svg/blog-bob-single.svg"
import sugandh from "../../assets/projects and logos/RUDRA -01.png";
import arrowRightBlue from "../../assets/svg/ArrowRightBlue.svg"
import blog_1 from "../../assets/blog/blog1.svg"
import blog_2 from "../../assets/blog/blog2.svg"
import blog_3 from "../../assets/blog/blog3.svg"
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";

const Blog_single = () => {
    // Set loading state to true initially
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [postData, setpostData] = useState()
    const [currentCategory, setcurrentCategory] = useState()

    useEffect(() => {
        setpostData(location?.state?.post_data);
    },
        [location?.state?.post_data]
    );

    useEffect(() => {
        setcurrentCategory(location?.state?.itemId);
    },
        [location?.state?.itemId]
    );

    const viewBlog = (post_data, itemId) => {
        navigate('/blog-single', { state: { post_data: post_data, itemId } });
    }

    console.log(postData);
    console.log(currentCategory);

    useEffect(() => {
        // Loading function to load data or 
        // fake it using setTimeout;
        const loadData = async () => {

            // Wait for two second
            // await new Promise((r) => setTimeout(r, 2000));

            setTimeout(() => {
                setLoading(false);
            }, 10);

            // Toggle loading state
            // setLoading((loading) => !loading);
        };

        loadData();
    }, [])

    // If page is in loading state, display 
    // loading message. Modify it as per your 
    // requirement.
    if (loading) {
        return <Loader_page />
    }

    // If page is not in loading state, display page.
    else {
        return (
            <>
                <Helmet>
                    <title>Bintech Services | Blog-single</title>
                    <meta name="title" content="Bintech Services your business growth hacker" />
                    <meta name="description" content="Designing brand perception with neuromarketing and behavioural science to enhance customer experience for your business growth." />
                    <meta name="keywords" content="Business Growth, Strategic Consultant, Business growth hacker, Strategic Consultant in Gujarat, Strategic Business Consultant in India, Best Business Consultant" />
                </Helmet>

                <div className="blog-single blog service">
                    {/* section 1 */}
                    <div className="section-1">
                        <div className="blog-single-blob">
                            <img src={blog_single_bob} alt="" className="img-fluid" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                    <div className="container">
                                        <h2 className="page-title">
                                            {postData?.name}
                                        </h2>
                                        <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="breadcrumb-item"><a href="/">Blog</a></li>
                                                <li className="breadcrumb-item active" aria-current="page"><a href="/services/business-growth">{postData?.name}</a></li>
                                            </ol>
                                        </nav>
                                        <h2 className="text-orange mb-3">
                                            {postData?.title}
                                        </h2>
                                        <p className="w-75">
                                            {postData?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-md-flex flex-column justify-content-center">
                                    <img src={blog_single_hero} alt="" className="img-fluid" />
                                    {/* <Business_ className="img-fluid" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section 1 */}

                    {/* section 2 */}
                    <div className="section-2">
                        <div className="container">
                            <div className="container">
                                <h2 className="text-orange mb-5">Table of Contents</h2>
                            </div>

                            <div className="container">
                                {
                                    postData?.post_contents?.map((item, i) => {
                                        return (
                                            <div key={i}>
                                                <h5 className="text-decoration-underline">
                                                    {item?.name}
                                                </h5>

                                                <ul className="mb-5">
                                                    {
                                                        item?.subtopics?.map((item, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <p className="mb-0">{item?.title}</p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* section 2 */}

                    {/* blog-content */}
                    <div className="blog-content">
                        <div className="scrollspy-nav-container container">
                            <div className="row g-0">
                                {/* <div className="col-1 fs-4 d-flex justify-content-center align-items-center rounded-start">
                                    <div className="swiper-custom-navigation-btn swiper-button-prev-">
                                        <BsArrowLeft />
                                    </div>
                                </div> */}

                                <div className="col-12 p-3">
                                    <Swiper
                                        modules={[Navigation, FreeMode]}
                                        spaceBetween={30}
                                        slidesPerView={1.5}
                                        freeMode
                                        // navigation={
                                        //     {
                                        //         nextEl: ".swiper-button-next-",
                                        //         prevEl: ".swiper-button-prev-",
                                        //     }
                                        // }
                                        breakpoints={
                                            {
                                                992: {
                                                    slidesPerView: 2.5
                                                },
                                                1200: {
                                                    slidesPerView: 4
                                                }
                                            }
                                        }
                                    >
                                        {
                                            postData?.post_contents?.map((item, i) => {
                                                return (
                                                    <SwiperSlide key={i}>
                                                        <Link activeClass="scrollspy-active-title" className="scrollspy-nav-item" to={"first" + (i + 1)} spy={true} smooth={false} offset={-300} >
                                                            {item?.nav_button_title}
                                                        </Link>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                                {/* <div className="col-1 fs-4 d-flex justify-content-center align-items-center rounded-end">
                                    <div className="swiper-custom-navigation-btn swiper-button-next-">
                                        <BsArrowRight />
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        {
                            postData?.post_contents?.map((item, i) => {
                                return (
                                    <div id={"first" + (i + 1)} className="blog-section-main" key={i}>
                                        <div className="blog-content-section-1">
                                            <div className="container">
                                            <div className={item?.difference ? "d-none" : "d-block"}>
                                                <div className="row g-0 deep">
                                                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center else block">
                                                        <div className="container">
                                                            <h4 className="text-orange mb-3">
                                                                {item?.name}
                                                            </h4>
                                                            <p>
                                                                {item?.description}
                                                            </p>
                                                        </div>
                                                    </div><div className="col-12 col-md-6 d-md-flex flex-column align-items-center justify-content-center">
                                                        <img src={item?.image_url} alt="" className="img-fluid" />
                                                    </div>
                                                </div>

                                                {
                                                    item?.stages?.map((item, i) => {
                                                        return (
                                                            <div className="row g-0">
                                                                <div className="col-12">
                                                                    <div className="container mb-4">
                                                                        <h2 className="text-blue">
                                                                            {item?.stage_main_title}
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>


                                                <div className="row g-0">
                                                    {
                                                        item?.stages?.map((jtem, j) => {
                                                            return (
                                                                <div key={j}>
                                                                    {
                                                                        jtem?.stage_data?.map((ktem, k) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center" key={k}>
                                                                                        <div className="container">
                                                                                            <h4 className="text-orange mb-3">
                                                                                                {ktem?.stage_title}
                                                                                            </h4>
                                                                                            <p className="mb-5">
                                                                                                {ktem?.stage_description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div><div className="col-12 col-md-6 d-md-flex flex-column align-items-center justify-content-center">
                                                                                        <img src={ktem?.image_url} alt="" className="img-fluid" />
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="table-responsive">
                                                        <table class="table table align-middle table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {item?.difference?.map((jtem, j) => (
                                                                        jtem?.difference_table_data?.map((ktem, k) => (
                                                                            ktem?.difference_table_header?.map((ltem, l) => (
                                                                                <th scope="col" key={l} className="text-orange px-4 py-5">{ltem?.table_header}</th>
                                                                            ))
                                                                        ))
                                                                    ))}
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                    {item?.difference?.map((jtem, j) => (
                                                                        jtem?.difference_table_data?.map((ktem, k) => (
                                                                            ktem?.difference_table_row?.map((ltem, l) => (
                                                                                <tr key={l}>
                                                                                    <th scope="row" key={l} className="text-orange px-4 py-5">{ltem?.table_row_header}</th>
                                                                                    {
                                                                                        ltem?.table_row_data?.map((mtem, m) => (
                                                                                            <td key={m} className="text-blue px-4 py-5">{mtem?.table_row_content}</td>
                                                                                        ))
                                                                                    }
                                                                                </tr>
                                                                            ))
                                                                        ))
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>



                                                    {/* {
                                                        item?.stages > 0 ?
                                                            <>
                                                                {
                                                                    item?.stages?.map((jtem, j) => {
                                                                        return (
                                                                            <div key={j}>
                                                                                {
                                                                                    jtem?.stage_data?.map((ktem, k) => {
                                                                                        return (
                                                                                            <><div className="col-12 col-md-6 d-flex flex-column justify-content-center" key={k}>
                                                                                                <div className="container">
                                                                                                    <h4 className="text-orange mb-3">
                                                                                                        {ktem?.stage_title}
                                                                                                    </h4>
                                                                                                    <p>
                                                                                                        {ktem?.stage_description}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div><div className="col-12 col-md-6 d-md-flex flex-column align-items-center justify-content-center">
                                                                                                    <img src={ktem?.image_url} alt="" className="img-fluid" />
                                                                                                </div></>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        
                                                        :

                                                        <>
                                                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center else block">
                                                                <div className="container">
                                                                    <h4 className="text-orange mb-3">
                                                                        {item?.name}
                                                                    </h4>
                                                                    <p>
                                                                        {item?.description}
                                                                    </p>
                                                                </div>
                                                            </div><div className="col-12 col-md-6 d-md-flex flex-column align-items-center justify-content-center">
                                                                    <img src={item?.image_url} alt="" className="img-fluid" />
                                                                </div>
                                                        </>
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* blog-content */}

                    {/* reference */}
                    <div className="reference">
                        <div className="container">
                            <div className="container">
                                <h2 className="text-orange mb-5">References</h2>
                            </div>

                            <div className="container">
                                <div className="row gy-3">
                                    {
                                        postData?.reference?.map((item, i) => {
                                            return (
                                                <div className="col-12">
                                                    <a href={item?.link} target="_blank">
                                                        <p className="mb-0">
                                                            {item?.link}
                                                        </p>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* reference */}

                    {/* more blogs */}
                    <div className="section-4">
                        <div className="container">
                            <div className="row mb-5 d-none d-lg-flex">
                                <div className="col-6">
                                    <div className="container">
                                        <h1 className="fs-4 mb-0 pe-5 text-blue">
                                            <img src={astrik} alt="" className="astrik" />
                                            MORE BLOGS TO GREW <br /> YOUR BUSINESS
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6">
                                            {/* <div className="search-button">
                                            Search
                                        </div> */}
                                        </div>
                                        <div className="col-6">
                                            <div className="category-button">
                                                All Categories
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row latest-blog blog-section mt-4">
                                <div className="p-0 col-1 fs-4 d-flex justify-content-center align-items-center rounded-start">
                                    <div className="swiper-button-prev"></div>
                                </div>

                                <div className="col-10 col-sm-10 p-0">
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        navigation={
                                            {
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                            }
                                        }
                                    >
                                        {
                                            currentCategory?.posts?.map((item, i) => {
                                                return (
                                                    <SwiperSlide key={i}>
                                                        <div className="card h-100 text-bg-dark">
                                                            <img src={item?.thumbnail_image} className="card-img" alt={item?.name} />
                                                            <div className="card-img-overlay">
                                                                <div className="animated-button d-flex align-items-center" onClick={() => viewBlog(item)}>
                                                                    <div className="arrowRightBlue arrowRightBlue-left">
                                                                        <BsArrowRight />
                                                                    </div>
                                                                    <div className="button-text">
                                                                        Read More
                                                                    </div>
                                                                    <div className="arrowRightBlue arrowRightBlue-right">
                                                                        <BsArrowRight />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                                <div className="p-0 col-1 fs-4 d-flex justify-content-center align-items-center rounded-end">
                                    <div className="swiper-button-next"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* more blogs */}
                </div>
                {/* footer */}
                <Footer />
                {/* footer */}
            </>
        );
    }

};

export default Blog_single