import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../components/Footer_services';
import herosvg from "../../assets/img/hero-svg.png"
import behance from "../../assets/social/behance.png"
import instagram from "../../assets/social/instagram.png"
import linkedin from "../../assets/social/linkedin.png"
import wp from "../../assets/social/wp.png"
import ArrowRight from "../../assets/svg/ArrowRight.svg"
import technology from "../../assets/svg/technology.svg"
import business from "../../assets/svg/business.svg"
import heart from "../../assets/svg/heart.svg"
import problem from "../../assets/svg/problem.svg"
import bulb from "../../assets/svg/bulb.svg"
import pencil from "../../assets/svg/pencil.svg"
import notebook from "../../assets/svg/notebook.svg"
import pen from "../../assets/svg/services_pen.svg"
import astrik from "../../assets/svg/astrik.svg"
import Service_pen from "../../components/svgs/Service_pen"
import Service_pencil from "../../components/svgs/Service_pencil"
import Service_problem from "../../components/svgs/Service_problem"
import Service_bulb from "../../components/svgs/Service_bulb"
import Service_heart from "../../components/svgs/Service_heart"
import Service_notebook from "../../components/svgs/Service_notebook"
import Service_bulb_drop from "../../components/svgs/Service_bulb_drop"
import "../../css/home.css"
import "../../css/services.css"
import "../../css/svg/business_animated.css"
import Business_ from "../../components/svgs/Service_business";
import Loader_page from "../../components/Loader_page";


const Business_marketing = () => {
    // Set loading state to true initially
  const [loading, setLoading] = useState(true);
      
  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {

      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
    
  // If page is in loading state, display 
  // loading message. Modify it as per your 
  // requirement.
  if (loading) {
      return <Loader_page />
  }
    
  // If page is not in loading state, display page.
  else {
    return (
      <>
        <Helmet>
          <title>Bintech Services | Business Growth</title>
          <meta name="title" content="Bintech Services your business growth hacker" />
          <meta name="description" content="Designing brand perception with neuromarketing and behavioural science to enhance customer experience for your business growth." />
          <meta name="keywords" content="Business Growth, Strategic Consultant, Business growth hacker, Strategic Consultant in Gujarat, Strategic Business Consultant in India, Best Business Consultant" />
        </Helmet>

            <div className="service">
                {/* section 1 */}
                <div className="section-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                <div className="container">
                                    <h2 className="page-title">
                                        Business Growth
                                    </h2>
                                    <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item"><a href="#">Services</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a href="/services/business-growth">Business Growth</a></li>
                                        </ol>
                                    </nav>
                                    <h2 className="mb-3">
                                        Your Brand, <br /> Our Passion, <br /> Limitless Possibilities.
                                    </h2>
                                    <div className="img-fluid d-block d-md-none my-4">
                                        <Business_ className="img-fluid" />
                                    </div>
                                    <p className="w-75">
                                        Designing brand perception with neuromarketing and behavioral science to enhance customer experience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center">
                                <Business_ className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 1 */}

                {/* section 2 */}
                <div className="section-2">
                    <Service_pen />
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <div className="container">
                                    <h5 className="mb-5 mb-md-4 service-section-title text-white">
                                        What Do We Serve?
                                    </h5>
                                    <h1 className="display-6 mb-5 pe-5">
                                        <img src={astrik} alt="" className="astrik" />
                                        From Vision to Visibility: <br /> Your business success, our marketing solutions
                                    </h1>
                                </div>
                            </div>
                            <div className="col-12 d-none d-md-block"></div>
                        </div>
                        
                        <div className="row services">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="container">
                                    <h4>Email Marketing</h4>
                                    <h4>SEO</h4>
                                    <h4>SMM</h4>
                                    <h4>SMO</h4> 
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="container">
                                    <h4>Branding</h4>
                                    <h4>Content</h4>
                                    <h4>Marketing</h4>
                                    <h4>Campaign</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 2 */}

                {/* section 3 */}
                <div className="section-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="container">
                                    <h5 className="service-section-title mb-4">
                                        How WE DO IT?
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12 col-md-8">
                                <div className="container">
                                    <h1 className="display-6 text-blue">
                                        Going the extra mile with an extra bit effort at every step.
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="d-block d-md-none">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                                            <Service_pencil className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <p className="m-0 ps-3">Understand Objective</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                                            <Service_problem className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <p className="m-0 ps-3">Right Technology</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                                            <Service_bulb className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <p className="m-0 ps-3">Development</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                                            <Service_heart className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <p className="m-0 ps-3">Careful Testing</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                                            <Service_notebook className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <p className="m-0 ps-3">Support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-0 d-none d-md-flex">
                                <div className="col-12 col-sm-15 text-center">
                                    <div className="card h-100">
                                        <div className="card-body">
                                                <Service_pencil />
                                            <p className="card-text">
                                            Understand Objective
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-15 text-center">
                                    <div className="card h-100">
                                        <div className="card-body">
                                                <Service_problem />
                                            <p className="card-text">
                                            Right Technology
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-15 text-center">
                                    <div className="card h-100">
                                        <div className="card-body">
                                                <Service_bulb />
                                            <p className="card-text">
                                            Development
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-15 text-center">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <Service_heart />
                                            <p className="card-text">
                                            Careful Testing
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-15 text-center">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <Service_notebook />
                                            <p className="card-text">
                                            Support
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 3 */}

                {/* section 5 */}
                <div className="section-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="container">
                                    <h5 className="service-section-title mb-4">
                                        Explore more
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-12 col-lg-4">
                                <div className="card h-100 border-start-0">
                                    <div className="card-body p-3 p-lg-5 text-center">
                                        <a href="/services/design-agency">
                                            <h2 className="card-title">Design</h2>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card h-100">
                                    <div className="card-body p-3 p-lg-5 text-center">
                                        <a href="/services/technology">
                                            <h2 className="card-title">Technology</h2>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card h-100 border-end-0">
                                    <div className="card-body p-3 p-lg-5 text-center">
                                        <a href="/services/network-and-security">
                                            <h2 className="card-title">Security</h2>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 5 */}
            </div>
            {/* footer */}
            <Footer />
            {/* footer */}
      </>
    );
  }
    
  };
  
  export default Business_marketing