import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "swiper/css/scrollbar";
// import AnimatedCursor from "react-animated-cursor"
import OurWorkSlider from "../../components/Our_work_slider";
import Footer from "../../components/Footer";
import herosvg from "../../assets/img/hero-svg.png";
import behance from "../../assets/social/behance.png";
import instagram from "../../assets/social/instagram.png";
import linkedin from "../../assets/social/linkedin.png";
import wp from "../../assets/social/wp.png";
import ArrowRight from "../../assets/svg/ArrowRight.svg";
import scrollDown from "../../assets/svg/home-down-arrow.svg";
import astrik from "../../assets/svg/astrik.svg";
// import blob from "../../assets/img/blob.png";
import blob from "../../assets/img/blob.svg";
import "../../css/home.css";
// import video from "../../assets/video/Bintech Web promo (1).mp4";
import rudralogo from "../../assets/projects and logos/RUDRA -01.png";
import gujaratisahitya from "../../assets/projects and logos/Gujrati-sahitya-logo.png";
import vnvlogo from "../../assets/projects and logos/VnV logo file-05.png";
import sugandh from "../../assets/projects and logos/SUDANDH.png";
import chhhap from "../../assets/projects and logos/Chhaap logo.png";
import marisamjhan from "../../assets/projects and logos/mari-samjhan-logo.png";
import scoopy from "../../assets/projects and logos/scoopy-circular.png";
import Hero_home_svg from "../../components/svgs/Hero_home_svg";
import Loader_page from "../../components/Loader_page";
import Marquee from "react-fast-marquee";

import Iframe from "react-iframe";

const Home = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [cursorVariant, setCursorVariant] = useState("default");
  const [activeDiv, setActiveDiv] = useState(2);

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
    text: {
      height: 150,
      width: 150,
      x: mousePosition.x - 75,
      y: mousePosition.y - 75,
      backgroundColor: "orange",
      mixBlendMode: "color",
    },
  };

  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");

  const handleMouseOver = (number) => {
    setActiveDiv(number);
  };

  const handleMouseOut = () => {
    setActiveDiv(2);
  };

  const videoRef = useRef(null);

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log();
        } else {
          videoRef.current.pause();
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);

    observer.observe(videoRef.current);
  });

  return (
    <>
      <Helmet>
        <title>Bintech Services | Technology Consultant</title>
        <meta
          name="title"
          content="Bintech Services your technology consultant"
        />
        <meta
          name="description"
          content="From coding to branding, we at Bintech Services have got the digital recipe for your success!"
        />
        <meta
          name="keywords"
          content="Bintech Services, Technology Consultant, Technology Consultant in Ahmedabad, Technology Consultant in Gujarat, Technology Consultant in India, Best Technology Consultant, Best Technology Consultant in Ahmedabad, Best Technology Consultant in Gujarat, Best Technology Consultant in India"
        />
      </Helmet>

      <div className="home">
        {/* section 1 */}
        <div className="section-1 position-relative">
          <motion.img
            src={blob}
            alt=""
            className="blob img-fluid position-absolute top-0 end-0"
            transition={{ duration: 1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
          />
          <div className="container">
            <div className="row g-0 justify-content-center">
              <div className="col-12 col-sm-4"></div>
              <div className="col-10 col-sm-8">
                <motion.h1
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  viewport={{ once: true }}
                  className="display-5 text-md-center hero-text"
                >
                  {/* <h1> */}
                  From coding to branding, we've got the digital recipe for your
                  success!
                  {/* </h1> */}
                </motion.h1>
              </div>
            </div>
          </div>
          <div className="hero-svg pt-0 pe-0 pt-sm-5 pe-sm-5 p-md-0">
            <Hero_home_svg />
          </div>
        </div>
        {/* section 1 */}

        {/* section 2 */}
        <div className="section-2" id="sec-2">
          <div className="video-player">
            <div className="scroll-down-arrow-container d-none d-sm-flex">
              <svg
                className="scroll-down-arrow"
                width="16"
                height="111"
                viewBox="0 0 16 111"
              >
                <path
                  className="path"
                  d="M7.29289 110.707C7.68342 111.098 8.31658 111.098 8.70711 110.707L15.0711 104.343C15.4616 103.953 15.4616 103.319 15.0711 102.929C14.6805 102.538 14.0474 102.538 13.6569 102.929L8 108.586L2.34315 102.929C1.95262 102.538 1.31946 102.538 0.928932 102.929C0.538408 103.319 0.538408 103.953 0.928932 104.343L7.29289 110.707ZM8 69.5H7H8ZM7 0V69.5H9V0L7 0ZM7 69.5V110H9V69.5H7Z"
                  fill="#00597B"
                />
              </svg>
              {/* <svg viewBox="0 0 16 111" width="16" height="111" >
                    <path class="path" fill="#00597B" stroke="black" stroke-width="1" d="M7.29289 110.707C7.68342 111.098 8.31658 111.098 8.70711 110.707L15.0711 104.343C15.4616 103.953 15.4616 103.319 15.0711 102.929C14.6805 102.538 14.0474 102.538 13.6569 102.929L8 108.586L2.34315 102.929C1.95262 102.538 1.31946 102.538 0.928932 102.929C0.538408 103.319 0.538408 103.953 0.928932 104.343L7.29289 110.707ZM8 69.5H7H8ZM7 0V69.5H9V0L7 0ZM7 69.5V110H9V69.5H7Z" pathLength="1" />
                  </svg> */}
            </div>
            <video
              // src="https://storage.googleapis.com/scoopywear-25a86.appspot.com/1684214420330_BintechWebpromo.mp4"
              className="video"
              ref={videoRef}
              controls
            >
              <source
                src="https://storage.googleapis.com/bintech-services.appspot.com/project.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        {/* section 2 */}

        {/* section 3 */}
        <div className="section-3">
          <motion.div
            className="cursor"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            variants={variants}
            animate={cursorVariant}
          />

          {/* <AnimatedCursor
    color="0,0,0"
    innerSize={8}
    outerSize={35}
    innerScale={1}
    outerScale={1.7}
    outerAlpha={0}
    outerStyle={{
      border: '3px solid #FD7A0B'
    }}
  /> */}

          {/* <AnimatedCursor
    color="#FD7A0B"
    innerSize={8}
    outerSize={35}
    innerScale={1}
    outerScale={1.7}
    outerAlpha={0}
    hasBlendMode={true}
    outerStyle={{
      mixBlendMode: 'exclusion'
    }}
  /> */}

          <div className="container">
            <div className="row g-0 justify-content-center align-items-center">
              <div className="col-12 col-lg-8">
                <motion.div
                  transition={{ duration: 1 }}
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  viewport={{ once: true }}
                >
                  <div
                    className="text-center section-3-content"
                    onMouseEnter={textEnter}
                    onMouseLeave={textLeave}
                  >
                    <span>THE INTERSECTION OF </span>
                    <span>DESIGN, TECHNOLOGY BUSINESS AND SECURITY</span>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        {/* section 3 */}

        {/* section 4 */}
        <div className="section-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 d-none d-md-flex justify-content-end">
                <marquee
                  behavior="alternate"
                  direction="left"
                  scrollamount="10"
                  className="display-5"
                >
                  <div className="scrolling-text d-flex align-items-center">
                    <img src={astrik} alt="" className="astrik me-4" />
                    Field of Expertise
                  </div>
                </marquee>
              </div>
              <div className="col-12 col-md-12 d-flex d-md-none justify-content-start display-5">
                <div className="scrolling-text d-flex align-items-center">
                  <img src={astrik} alt="" className="astrik me-4" />
                  Field of Expertise
                </div>
              </div>
            </div>
          </div>

          <div className="home-cards mt-3 mt-lg-5">
            <div className="row g-0 d-none d-lg-flex">
              <div
                className="col-12 col-sm-6 col-md-3 technology"
                onMouseEnter={() => handleMouseOver(1)}
                onMouseLeave={() => handleMouseOut()}
              >
                <div
                  className={
                    "card h-100 " +
                    (activeDiv === 1 ? "card-bg-active" : "card-bg")
                  }
                >
                  <div className="card-body p-5 p-lg-4 p-xl-5">
                    <h4 className="card-title mb-5">Technology</h4>
                    <NavLink
                      to="/services/technology/software"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="card-text">
                      We optimize operations with cutting-edge technology for
                      increased efficiency and growth. Our expert team
                      implements innovative solutions to keep you ahead in the
                      competitive market.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-6 col-md-3 design"
                onMouseEnter={() => handleMouseOver(2)}
                onMouseLeave={() => handleMouseOut()}
              >
                <div
                  className={
                    "card h-100 " + (activeDiv === 2 ? "card-bg-active" : "")
                  }
                >
                  <div className="card-body p-5 p-lg-4 p-xl-5">
                    <h4 className="card-title mb-5">Design</h4>
                    <NavLink
                      to="/services/design-agency"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="card-text">
                      We create user-friendly, visually appealing interfaces
                      that enhance the user experience. Our customized design
                      solutions include web, app, UX/UI, and graphic design.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-6 col-md-3 business"
                onMouseEnter={() => handleMouseOver(3)}
                onMouseLeave={() => handleMouseOut()}
              >
                <div
                  className={
                    "card h-100 " +
                    (activeDiv === 3 ? "card-bg-active" : "card-bg")
                  }
                >
                  <div className="card-body p-5 p-lg-4 p-xl-5">
                    <h3 className="card-title mb-5">Business Growth</h3>
                    <NavLink
                      to="/services/business-growth"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="card-text">
                      BinTech is your growth partner, delivering customized IT
                      solutions for increased profitability. With our experts
                      and cutting-edge tech, we help businesses optimize
                      operations and reach their full potential.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-6 col-md-3"
                onMouseEnter={() => handleMouseOver(4)}
                onMouseLeave={() => handleMouseOut()}
              >
                <div
                  className={
                    "card h-100 " +
                    (activeDiv === 4 ? "card-bg-active" : "card-bg")
                  }
                >
                  <div className="card-body p-5 p-lg-4 p-xl-5">
                    <h3 className="card-title mb-5">Network and Security</h3>
                    <NavLink
                      to="/services/network-and-security"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="card-text">
                      Ensuring your data security and business privacy is our
                      responsibility. We provide a comprehensive range of
                      security solutions, including network security, data
                      encryption, vulnerability assessments, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="accordion accordion-flush d-block d-lg-none"
              id="accordionFlushExample"
            >
              <div class="accordion-item">
                <h3 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed p-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <h3 className="m-0">Technology</h3>
                  </button>
                </h3>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body px-4 pt-0 pb-5">
                    <NavLink
                      to="/services/technology"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="m-0 card-text">
                      We optimize operations with cutting-edge technology for
                      increased efficiency and growth. Our expert team
                      implements innovative solutions to keep you ahead in the
                      competitive market.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button p-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    <h3 className="m-0">Design</h3>
                  </button>
                </h3>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body px-4 pt-0 pb-5">
                    <NavLink
                      to="/services/design-agency"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="m-0 card-text">
                      We create user-friendly, visually appealing interfaces
                      that enhance the user experience. Our customized design
                      solutions include web, app, UX/UI, and graphic design.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed p-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    <h3 className="m-0">Business Growth</h3>
                  </button>
                </h3>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body px-4 pt-0 pb-5">
                    <NavLink
                      to="/services/business-growth"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="m-0 card-text">
                      BinTech is your growth partner, delivering customized IT
                      solutions for increased profitability. With our experts
                      and cutting-edge tech, we help businesses optimize
                      operations and reach their full potential.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h3 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed p-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    <h3 className="m-0">Network And Security</h3>
                  </button>
                </h3>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body px-4 pt-0 pb-5">
                    <NavLink
                      to="/services/network-and-security"
                      className="btn btn-light mb-5"
                    >
                      <img src={ArrowRight} alt="" className="img-fluid" />
                    </NavLink>
                    <p className="m-0 card-text">
                      Ensuring your data security and business privacy is our
                      responsibility. We provide a comprehensive range of
                      security solutions, including network security, data
                      encryption, vulnerability assessments, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 4 */}

        {/* section 5 */}
        <div className="section-5">
          <OurWorkSlider />
        </div>
        {/* section 5 */}

        {/* section 7 */}
        <div className="section-7">
          <div className="container">
            <h5 className="text-orange container">CLIENTS</h5>
            <p className="container">We had pleasure of working with</p>

            <div className="d-flex d-lg-none">
              <Marquee pauseOnHover={true}>
                <img
                  src={rudralogo}
                  alt="Rudra Logo"
                  className="img-fluid client-image"
                />
                <img
                  src={gujaratisahitya}
                  alt="Gujarati sahitya Logo"
                  className="img-fluid client-image client-gujarat-sahitya"
                />
                <img
                  src={sugandh}
                  alt="Sugandh Logo"
                  className="img-fluid client-image"
                />
                <img
                  src={vnvlogo}
                  alt="Vnv Logo"
                  className="img-fluid client-image client-vnv"
                />
                <img
                  src={chhhap}
                  alt="Chhhap Logo"
                  className="img-fluid client-image client-chaap"
                />
                <img
                  src={marisamjhan}
                  alt="Marisamjhan Logo"
                  className="img-fluid client-image"
                />
                <img
                  src={scoopy}
                  alt="Scoopy Logo"
                  className="img-fluid client-image"
                />
              </Marquee>
            </div>

            <div className="clients-list container d-none d-lg-block">
              <div className="row">
                <div className="col-2">
                  <img src={rudralogo} alt="Rudra Logo" className="img-fluid" />
                </div>
                <div className="col-2 gujarati-sahitya-logo">
                  <img
                    src={gujaratisahitya}
                    alt="Rudra Logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-2">
                  <img src={sugandh} alt="Rudra Logo" className="img-fluid" />
                </div>
                <div className="col-2">
                  <img src={vnvlogo} alt="Rudra Logo" className="img-fluid" />
                </div>
                <div className="col-2">
                  <img src={chhhap} alt="Rudra Logo" className="img-fluid" />
                </div>
                <div className="col-2">
                  <img
                    src={marisamjhan}
                    alt="Rudra Logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 6 */}
      </div>
      {/* footer */}
      <Footer />
      {/* footer */}
    </>
  );
};

export default Home;
