import { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Pagination, EffectFade, FreeMode } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'
import "swiper/css/free-mode";

import "../css/our-work-slider.css";

import img1 from "../assets/projects and logos/SUGANDH.png";
import img2 from "../assets/projects and logos/CHHAAP.png";
import img3 from "../assets/projects and logos/SOCIAL MEDIA.png";
import img4 from "../assets/projects and logos/VNV MEDIA.png";
import img5 from "../assets/projects and logos/GUJARATI SAHITYA.png";
import img6 from "../assets/projects and logos/mari-samjhan-project.png";
import nextProject from "../assets/img/image 3.png";
import arrow from "../assets/img/arrow (1).png";
import Ellipse from "../assets/img/Ellipse 22.png";

const OurWorkSlider = () => {
  const swiperParams = {
    initialSlide: 1, // Set the second slide as active (index 1)
  };
  const swiperRef = useRef(null);

  const handleSlideClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const activeIndex = swiperRef.current.swiper.realIndex;
      if (index < activeIndex) {
        swiperRef.current.swiper.slidePrev();
      } else if (index > activeIndex) {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return (
    <div className="container-fluid">
        <div className="container">
          <h5 className="text-orange">OUR RECENT WORK</h5>
        </div>
      <br />
      <br />

      <div className="d-block d-md-none">
        <Swiper
          modules={[Navigation, EffectCoverflow, Pagination, EffectFade, FreeMode]}
          grabCursor={true}
          spaceBetween={10}
          slidesPerView={2.5}
          centeredSlides={true}
          initialSlide={2}
          // effect="coverflow"
          // coverflowEffect={{
          //   rotate: 10,
          //   stretch: 0,
          //   scale: 1,
          //   depth: 50,
          //   modifier: 2,
          //   slideShadows: true
          // }}
          freeMode
          loop={true}
        >
          <SwiperSlide>
            <img src={img1} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="" className="img-fluid" />
          </SwiperSlide>{" "}
          <SwiperSlide>
            <img src={img3} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt="" className="img-fluid" />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="container d-none d-md-block">
        <Swiper
          {...swiperParams}
          // spaceBetween={10}
          slidesPerView="2.5"
          // freeMode={true}
          // watchSlidesProgress={true}
          slideToClickedSlide={true}
          modules={[FreeMode, Navigation, Pagination]}
          className="mySwiper"
          centeredSlides={true}
          // navigation
          pagination={{
            enabled: true,
            el: '.swiper-pagination',
            clickable: true,
          }}
          // freeMode
          // loop={true}
        >
          <SwiperSlide onClick={() => handleSlideClick(0)}>
            <img src={img1} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide onClick={() => handleSlideClick(1)}>
            <img src={img2} alt="" className="img-fluid" />
          </SwiperSlide>{" "}
          <SwiperSlide onClick={() => handleSlideClick(2)}>
            <img src={img3} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide onClick={() => handleSlideClick(3)}>
            <img src={img4} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide onClick={() => handleSlideClick(3)}>
            <img src={img5} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide onClick={() => handleSlideClick(3)}>
            <img src={img6} alt="" className="img-fluid" />
          </SwiperSlide>
        </Swiper>
      </div>

      <br />

      <div className="container d-none d-md-block">
          <div className="position-relative">
            <div class="swiper-pagination">
            </div>
          </div>
      </div>

        {/* <Swiper
          modules={[Navigation, EffectCoverflow, Pagination, EffectFade, FreeMode]}
          grabCursor={true}
          // spaceBetween={100}
          slidesPerView={2.5}
          centeredSlides={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 10,
            stretch: 0,
            scale: 1,
            depth: 50,
            modifier: 2,
            slideShadows: true
          }}
          pagination={{
            enabled: true,
            clickable: true,
            // dynamicBullets: true,
            // dynamicMainBullets: 1,
          }}
          // loop={true}
        // breakpoints={
        //     {
        //         875: {
        //             pagination: {
        //                 enabled: true,
        //                 clickable: true,
        //                 dynamicBullets: false,
        //             },
        //             direction: "vertical",
        //             allowTouchMove: false
        //         },
        //     }
        // }
        >
          <SwiperSlide>
            <img src={img1} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="" className="img-fluid" />
          </SwiperSlide>{" "}
          <SwiperSlide>
            <img src={img3} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt="" className="img-fluid" />
          </SwiperSlide>
        </Swiper> */}
    </div>
  );
};
export default OurWorkSlider;






      {/* <Swiper
        {...swiperParams}
        spaceBetween={10}
        slidesPerView="auto"
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
        centeredSlides={true}
        // navigation
        pagination={{ clickable: true }}
      >
        <SwiperSlide onClick={() => handleSlideClick(0)}>
          <img src={img1} alt="" className="img-fluid" />
        </SwiperSlide>
        <SwiperSlide onClick={() => handleSlideClick(1)}>
          <img src={img2} alt="" className="img-fluid" />
        </SwiperSlide>{" "}
        <SwiperSlide onClick={() => handleSlideClick(2)}>
          <img src={img3} alt="" className="img-fluid" />
        </SwiperSlide>
        <SwiperSlide onClick={() => handleSlideClick(3)}>
          <img src={img4} alt="" className="img-fluid" />
        </SwiperSlide>
        <SwiperSlide onClick={() => handleSlideClick(3)}>
          <img src={img5} alt="" className="img-fluid" />
        </SwiperSlide>
        <SwiperSlide onClick={() => handleSlideClick(3)}>
          <img src={img6} alt="" className="img-fluid" />
        </SwiperSlide>
      </Swiper> */}
{/* 
      <br />
      <div className="container next-project">
        <img src={nextProject} alt="Next Project"/>
      </div> */}