import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/technology-animation.json";
import Footer from "../../components/Footer";
import astrik from "../../assets/svg/astrik.svg";
import arrowRightBlue from "../../assets/svg/ArrowRightBlue.svg"
import hardware from "../../assets/lotties/hardware-svg-animation.json";
import software from "../../assets/lotties/software-svg-animation.json";
import Service_pen from "../../components/svgs/Service_pen"
import "../../css/technology.css";
import Technology_svg from "../../components/svgs/Service_technology"
import Loader_page from "../../components/Loader_page";

const Technology = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const hardwareAnimation = {
        loop: false,
        autoplay: true,
        animationData: hardware,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }

    const softwareAnimation = {
        loop: false,
        autoplay: true,
        animationData: software,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }

    // Set loading state to true initially
  const [loading, setLoading] = useState(true);
      
  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {

      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
    
  // If page is in loading state, display 
  // loading message. Modify it as per your 
  // requirement.
  if (loading) {
      return <Loader_page />
  }
    
  // If page is not in loading state, display page.
  else {
    return (
        <div className="service technology">
            {/* section 1 */}
            <div className="section-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                            <div className="container">
                                <h2 className="page-title">Technology</h2>
                                <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#services">Service</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="/services/technology">Technology</a>
                                        </li>
                                    </ol>
                                </nav>

                                <h2 className="mb-3">
                                Powering Innovation, <br />
                                Redefining Possibilities.
                                </h2>
                                <div className="img-fluid d-block d-md-none my-4">
                                    <Technology_svg />
                                </div>
                                <p className="w-75">
                                    Where innovation meets implementation, powering your tech dreams from circuits to code!
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-none d-md-flex justify-content-center align-items-center">
                            <Technology_svg />
                        </div>
                    </div>
                </div>
            </div>
            {/* section 1 */}

            {/* section 2 */}
            <div className="section-2">
                {/* <Service_bulb_drop /> */}
                <Service_pen />
                <div className="container">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-5 pe-3 mb-md-4 service-section-title text-white semi-bold">
                            What Do We Serve?
                        </h5>
                        <h1 className="display-4 mb-5 pe-5 d-none d-sm-block">
                            <img src={astrik} alt="" className="astrik" />
                        </h1>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row mt-0 mt-md-5">
                            <div className="col-12 col-md-6">
                                <Lottie
                                    className="design-animation"
                                    options={softwareAnimation}
                                    style={{
                                        width: "60px",
                                        height: "70px",
                                        margin: "0"
                                    }}
                                /><br />
                                <h4 className="text-orange semi-bold">Software</h4>
                                <div className="mt-5">
                                    <p>
                                    Stay ahead of the technology <br/> curve with our innovative <br/> software solutions.
                                    </p>
                                    <br />
                                    <br />
                                    <a href="/services/technology/software" className="animated-button d-flex align-items-center">
                                        <div className="arrowRightBlue arrowRightBlue-left">
                                            <img src={arrowRightBlue} alt="" />
                                        </div>
                                        <div className="button-text">
                                            View More
                                        </div>
                                        <div className="arrowRightBlue arrowRightBlue-right">
                                            <img src={arrowRightBlue} alt="" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-5 mt-md-0">
                                <Lottie
                                    className="design-animation"
                                    options={hardwareAnimation}
                                    style={{
                                        width: "85px",
                                        height: "70px",
                                        margin: "0"
                                    }}
                                /><br />
                                <h4 className="text-orange semi-bold">Hardware</h4>
                                <div className="mt-5">
                                    <p>
                                        Powering your business with <br/> robust hardware solutions <br/> designed for maximum efficiency <br/> and productivity.
                                    </p>
                                    <br />
                                    <br />
                                    <a href="/services/technology/hardware" className="animated-button d-flex align-items-center">
                                        <div className="arrowRightBlue arrowRightBlue-left">
                                            <img src={arrowRightBlue} alt="" />
                                        </div>
                                        <div className="button-text">
                                            View More
                                        </div>
                                        <div className="arrowRightBlue arrowRightBlue-right">
                                            <img src={arrowRightBlue} alt="" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 */}

            {/* section 3 */}
        <div className="section-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <h5 className="service-section-title mb-4">Explore more</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-0">
              <div className="col-12 col-lg-4">
                <div className="card h-100 border-start-0">
                  <div className="card-body p-3 p-lg-5 text-center">
                    <a href="/services/e-commerce">
                      <h2 className="card-title">E-Commerce</h2>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card h-100">
                  <div className="card-body p-3 p-lg-5 text-center">
                    <a href="/services/business-growth">
                      <h2 className="card-title">Business Growth</h2>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card h-100 border-end-0">
                  <div className="card-body p-3 p-lg-5 text-center">
                    <a href="/services/network-and-security">
                      <h2 className="card-title">Security</h2>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 3 */}


            <Footer />
        </div>
    )
  }
}
export default Technology;