import Header from './components/Header';
import Footer from './components/Footer';
import Home from './modules/home/Home'
import React, { useEffect, useState, useRef } from "react";
import Loader_page from "../src/components/Loader_page";
import './App.css';

function App() {

  // Set loading state to true initially
  const [loading, setLoading] = useState(true);
      
  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {

      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
    
  // If page is in loading state, display 
  // loading message. Modify it as per your 
  // requirement.
  if (loading) {
      return <Loader_page />
  }
    
  // If page is not in loading state, display page.
  else {
  return (
    <div className="App">
      {/* <Home /> */}
      <Header />
      {/* <Footer /> */}
    </div>
  );
  }
}

export default App;
