import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Footer from '../../components/Footer_services';
import astrik from "../../assets/svg/astrik.svg"
import "../../css/home.css"
import "../../css/services.css"
import "../../css/blog.css"
import "../../css/svg/business_animated.css"
import arrowRightBlue from "../../assets/svg/ArrowRightBlue.svg"
import blog_hero from "../../assets/svg/blog-hero.svg"
import Loader_page from "../../components/Loader_page";
import blog_bob from "../../assets/svg/blog-bob.svg"
import section_3_img from "../../assets/svg/blog-section-3.svg"
import section_4_img from "../../assets/svg/blog-section-4.svg"
import section_5_img from "../../assets/svg/blog-section-5.svg"
import section_6_img from "../../assets/svg/blog-section-6.svg"
import BlogData from "../../_blog_data/blog_data.json"
import { color } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";

const Blog = () => {
    // Set loading state to true initially
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [BlogAllData, setBlogAllData] = useState(BlogData);

    const viewBlog = (post_data, itemId) => {
        navigate('/blog-single', { state: { post_data: post_data, itemId } });
    }

    useEffect(() => {
        // Loading function to load data or 
        // fake it using setTimeout;
        const loadData = async () => {

            // Wait for two second
            // await new Promise((r) => setTimeout(r, 2000));

            setTimeout(() => {
                setLoading(false);
            }, 10);

            // Toggle loading state
            // setLoading((loading) => !loading);
        };

        loadData();
    }, [])
    
  // If page is in loading state, display 
  // loading message. Modify it as per your 
  // requirement.
  if (loading) {
      return <Loader_page />
  }
    
  // If page is not in loading state, display page.
  else {
    return (
      <>
        <Helmet>
          <title>Bintech Services | Blog</title>
          <meta name="title" content="Bintech Services your business growth hacker" />
          <meta name="description" content="Designing brand perception with neuromarketing and behavioural science to enhance customer experience for your business growth." />
          <meta name="keywords" content="Business Growth, Strategic Consultant, Business growth hacker, Strategic Consultant in Gujarat, Strategic Business Consultant in India, Best Business Consultant" />
        </Helmet>

            <div className="blog service">
                {/* section 1 */}
                <div className="section-1">
                    <div className="blog-bob">
                        <img src={blog_bob} alt="" className="img-fluid" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                <div className="container">
                                    <h2 className="page-title">
                                        Blog
                                    </h2>
                                    <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"><a href="/services/business-growth">Blog</a></li>
                                        </ol>
                                    </nav>
                                    <h2 className="text-orange mb-3">
                                        Visionary Perspectives
                                    </h2>
                                    <div className="img-fluid d-block d-md-none my-4">
                                        <img src={blog_hero} alt="" className="img-fluid" />
                                        {/* <Business_ className="img-fluid" /> */}
                                    </div>
                                    <p className="w-75">
                                        Unraveling the Future-oriented <br /> Tapestry of creative achievements. 
                                    </p>
                                    <NavLink to="/" className="animated-button d-flex align-items-center">
                                        <div className="arrowRightBlue arrowRightBlue-left">
                                        <BsArrowRight />
                                        </div>
                                        <div className="button-text">
                                            Read More
                                        </div>
                                        <div className="arrowRightBlue arrowRightBlue-right">
                                        <BsArrowRight />
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center">
                                <img src={blog_hero} alt="" className="img-fluid" />
                                {/* <Business_ className="img-fluid" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 1 */}

                {/* section 3 */}
                {BlogAllData?.map((item, i) => {
                    return (
                        <div className="section-3" 
                            key={i}
                            style={
                                {
                                    backgroundColor: (i+1)%2 ? "#00597B" : "#fff",
                                    color: (i+1)%2 ? "#fff" : "#000",
                                    // color: console.log(i)
                                }
                            }
                        >
                            <img src={section_3_img} alt="" className="img-fluid custom-image" />
                            <div className="container">
                                <div className="row g-0 mb-5 d-none d-lg-flex">
                                    <div className="col-6 d-flex align-items-center">
                                        <div className="container">
                                            <h1 className="fs-4 mb-0 pe-5">
                                                <img src={astrik} alt="" className="astrik" />
                                                OUR BLOGS
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="search-button">
                                                    Search
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="category-button">
                                                    All Categories
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-0">
                                    <div className="col-12 col-md-7">
                                        <div className="container">
                                            <h1 className="fs-4 pe-5">
                                                {item?.name}
                                            </h1>
                                            <p className="w-50">
                                                {item?.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-none d-md-block"></div>
                                </div>

                                <div className="row g-0 latest-blog blog-section mt-4">
                                    <div className="col-1 fs-4 d-flex justify-content-center align-items-center rounded-start">
                                        <div className={"swiper-custom-navigation-btn swiper-button-prev-" + (i)}>
                                            <BsArrowLeft />
                                        </div>
                                    </div>

                                    <div className="col-10 col-sm-10 p-3">
                                        <Swiper
                                            modules={[Navigation, FreeMode]}
                                            spaceBetween={30}
                                            slidesPerView={1}
                                            freeMode
                                            navigation={
                                                {
                                                    nextEl: `.swiper-button-next-${i}`,
                                                    prevEl: `.swiper-button-prev-${i}`,
                                                }
                                            }
                                            breakpoints={
                                                {
                                                    576: {
                                                        slidesPerView: 2
                                                    },
                                                    1200: {
                                                        slidesPerView: 3
                                                    }
                                                }
                                            }
                                        >
                                            {
                                                item?.posts?.map((jtem, j) => {
                                                    return (
                                                        <SwiperSlide key={j} className="h-100">
                                                            <div class="blog-post-card-layout card h-100" onClick={() => viewBlog(jtem, item)}>
                                                                <img src={jtem?.thumbnail_image} className="card-img" alt={jtem?.name} />
                                                                <div class="card-body">
                                                                    <p class="card-text">{jtem?.title}</p>
                                                                    <h5 class="card-title">{jtem?.name}</h5>
                                                                    <div className="animated-button d-flex align-items-center">
                                                                        <div className="arrowRightBlue arrowRightBlue-left">
                                                                        <BsArrowRight />
                                                                        </div>
                                                                        <div className="button-text">
                                                                            Read More
                                                                        </div>
                                                                        <div className="arrowRightBlue arrowRightBlue-right">
                                                                        <BsArrowRight />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="card h-100 text-bg-dark">
                                                                <img src={jtem?.thumbnail_image} className="card-img" alt={jtem?.name} />
                                                                <div className="card-img-overlay">
                                                                    <div className="animated-button d-flex align-items-center" onClick={() => viewBlog(jtem, item)}>
                                                                        <div className="arrowRightBlue arrowRightBlue-left">
                                                                        <BsArrowRight />
                                                                        </div>
                                                                        <div className="button-text">
                                                                            Read More
                                                                        </div>
                                                                        <div className="arrowRightBlue arrowRightBlue-right">
                                                                        <BsArrowRight />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                    <div className="col-1 fs-4 d-flex justify-content-center align-items-center rounded-end">
                                        <div className={"swiper-custom-navigation-btn swiper-button-next-" + (i)}>
                                            <BsArrowRight />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* footer */}
            <Footer />
            {/* footer */}
      </>
    );
  }
    
  };
  
  export default Blog