import { useRef } from "react";
// import logo from "../assets/img/logo_blue_svg.svg";
import logo from "../assets/img/b.svg";
import { ColorRing } from  'react-loader-spinner';

const Loader_page = () => {

  return (
    <div className="loader">
        <img src={logo} alt="" className="img-fluid" />
        {/* <ColorRing
    visible={true}
    height="80"
    width="80"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
  /> */}
    </div>
  );
};
export default Loader_page;